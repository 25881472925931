import { useEffect, useState } from 'react';
import SERVER_URL from '../util/SERVER_URL'
import { DataGrid } from '@mui/x-data-grid';
import Navigation from './Navigation';
import CustomToolbar from './CustomToolbar';

function Sessions(props){

    const [loading, setLoading] = useState(true);
    const [filterByPilot, setFilterByPilot] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [user, setUser] = useState(null)
    const [rows, setRows] = useState([]);
    const columns = [
        // {
        //     field: "signedInAt",
        //     headerName: "Signed in at",
        //     width: 150,
        //     hide: ({ row }) => row.type !== "child",
        // },
        {
            field: "signedInAt",
            headerName: "Signed in at",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return (
                <div>
                    {row.type === "parent" && row.children ? (
                    <button onClick={() => row.toggleExpand(row.id)}>
                        {row.isExpanded ? "-" : "+"}
                    </button>
                    ) : null}
                    {row.signedInAt}
                </div>
                );
            },
        },
        {
            field: "signedOutAt",
            headerName: "Signed out at",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "sessionDuration",
            headerName: "Session duration",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "totalAttempts",
            headerName: "Total attempts",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "totalQuestionsCompleted",
            headerName: "Total questions completed",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "totalRetries",
            headerName: "Total retries",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "questionId",
            headerName: "Exits from question",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
        
                if (row.type === "child") {
                    return <span>Question {row.questionId}</span>
                }
            },
            valueGetter: (value, row) => {
                let newValue = "";
      
                if (row.type === "child") {
                    newValue = `Question ${row.questionId}`
                }
      
                return newValue;
            },
        },
        {
            field: "numExitedFromStart",
            headerName: "Exited from instructions page",
            width: 150,
            hide: ({ row }) => row.type !== "parent",
        },
        {
            field: "numExitedDuring",
            headerName: "Exited from task page (including prep time)",
            width: 150,
            hide: ({ row }) => row.type !== "parent",
        },
        {
            field: "numExitedOnScoreLoad",
            headerName: "Exited while score was loading",
            width: 150,
            hide: ({ row }) => row.type !== "parent",
        },
    ]

    const toggleExpand = (id) => {
        setRows((prev) =>
          prev.map((row) =>
            row.id === id ? { ...row, isExpanded: !row.isExpanded } : row
          )
        );
    };

    const expandRow = (id) => {
        setRows((prev) =>
            prev.map((row) =>
            row.id === id ? { ...row, isExpanded: true } : row
            )
        );
    };

    const collapseRow = (id) => {
        setRows((prev) =>
            prev.map((row) =>
            row.id === id ? { ...row, isExpanded: false } : row
            )
        );
    };
      
    const flattenedRows = rows.flatMap((row) => {
        if (!row.isExpanded || !row.children) return [row];

        // Calculate aggregate value for parent rows
        const aggregateDetail = row.children.reduce(
            (sum, child) => sum + (child.detail || 0),
            0
        );

        // return [row, ...row.children];
        return [{ ...row, detail: aggregateDetail }, ...row.children];
    });

    const selectUser = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const user = JSON.parse(selectedOption.getAttribute('data-user'));
        setUser(user)
    }

    const downloadFeedback = (id) => {
        let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
        if(authPassword){
            fetch(`${SERVER_URL}/download-feedback/${id}`, {
                method: "get",
                headers: {
                    'Authorization': 'Basic ' + btoa(`${authPassword}`)
                }
            })
            .then( async (response)=>{
                if(response.ok){
                    // Get the Blob from the response
                    const blob = await response.blob();

                    // Create a temporary download link
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `score_report_${id}.json`); // Specify the filename
                    document.body.appendChild(link);
                    link.click(); // Trigger the download

                    // Clean up
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url); // Release the URL object
                }
            })
        }
    }

    useEffect(()=>{
        let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
        if(authPassword){
            fetch(`${SERVER_URL}/users/simple`, {
                method: "get",
                headers: {
                    'Authorization': 'Basic ' + btoa(`${authPassword}`)
                }
            })
            .then(response=>{
                if (response.ok) {
                    return response.json()
                }else{
                    props.removePassword()
                    throw new Error('Unauthorized');
                }
            })
            .then(users=>{
                users.sort((a, b) => {
                    if (a.firstName > b.firstName) return 1;
                    if (a.firstName < b.firstName) return -1;
                    return 0;
                  });
                setAllUsers(users)
                setFilteredUsers(users)
                setUser(users[0])
            })
        }
    },[props.password])

    useEffect(()=>{
        let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
        if(user && authPassword){
            if(loading == false){setLoading(true)}
            fetch(`${SERVER_URL}/sessions/${user._id}`, {
                method: "get",
                headers: {
                    'Authorization': 'Basic ' + btoa(`${authPassword}`)
                }
            })
            .then(response=>response.json())
            .then(sessions=>{
                // console.log(sessions)
                let rowData = []
                sessions.forEach((session, i)=>{
                    let newItem = {
                        id: `parent-${i}`,
                        type: "parent",
                        isExpanded: false,
                        signedInAt: session.signedInAt,
                        signedOutAt: session.signedOutAt,
                        sessionDuration: session.sessionDuration,
                        totalAttempts: session.totalAttempts,
                        totalQuestionsCompleted: session.totalQuestionsCompleted,
                        totalRetries: session.totalRetries,
                        children: [],
                    }
                    session.questionAbandonment.sort((a, b) => a.quesitonId - b.questionId).forEach((question, j)=>{
                        let newQuestion = {
                            id: `child-${i}-${j}`,
                            type: "child",
                            questionId: question.questionId,
                            numExitedFromStart: question.numExitedFromStart ? question.numExitedFromStart : "",
                            numExitedDuring: question.numExitedDuring ? question.numExitedDuring : "",
                            numExitedOnScoreLoad: question.numExitedOnScoreLoad ? question.numExitedOnScoreLoad : ""
                        }
                        newItem.children.push(newQuestion)
                    })
                    rowData.push(newItem)
                })
                setRows(rowData)
                setLoading(false);
            })
        }
    },[user, props.password])

    useEffect(()=>{
        if(filterByPilot === true){
          let newFilteredUsers = allUsers.filter(user=>user.pilotUser === true)
            setFilteredUsers(newFilteredUsers)
            setUser(newFilteredUsers[0])
        }else{
            setFilteredUsers(allUsers)
            setUser(allUsers[0])
        }
      },[filterByPilot])


    return(
        <>
            <h1>pte-ai-prep-poc Analytics</h1>
            <Navigation/>
            <div>
                <input 
                        type="checkbox" 
                        id="pilot-user-input"
                        name="pilot-user"
                        value={filterByPilot}
                        onChange={(event)=>{setFilterByPilot(event.target.checked)}}
                        disabled={loading}
                    />
                    <label 
                        htmlFor='pilot-user-input'
                    >
                        Show only pilot users
                    </label>
                </div>
            <select
                onChange={(e)=>{selectUser(e)}}
                disabled={loading}
            >
                {
                    filteredUsers.map((user, index)=>{
                        return(
                            <option 
                                key={index} 
                                data-user={JSON.stringify(user)}
                            >
                                    {user.firstName} {user.lastName}
                            </option>
                        )
                    })
                }
            </select>
            {
                user ?
                <>
                    <p>Current score: {user.goalScore.currentScore}</p>
                    <p>Goal score: {user.goalScore.goalScore}</p>
                </>
                : null
            }
            <DataGrid
                disableColumnSorting
                disableColumnFilter
                disableRowSelectionOnClick
                rows={flattenedRows.map((row) => ({
                    ...row,
                    toggleExpand,
                }))}
                columns={columns}
                loading={loading}
                getRowId={(row) => row.id}
                slots={{
                    toolbar: () => <CustomToolbar rows={rows} expandRow={expandRow} collapseRow={collapseRow}/>,
                }}
            />
        </>
    )
}

export default Sessions