import { Link } from 'react-router-dom';

function Navigation(){
    return(
        <nav>
        <ul>
            <li>
            <Link to="/">Users</Link>
            </li>
            <li>
            <Link to="/sessions">Sessions</Link>
            </li>
            <li>
            <Link to="/attempts">Attempts</Link>
            </li>
            <li>
            <Link to="/surveys">Surveys</Link>
            </li>
            <li>
            <Link to="/feedback">Feedback</Link>
            </li>
        </ul>
        </nav>
    )
}

export default Navigation