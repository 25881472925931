import { useEffect, useState } from 'react';
import SERVER_URL from '../util/SERVER_URL'
import { DataGrid, GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import Navigation from './Navigation';
import CustomToolbar from './CustomToolbar';


function CustomerFeedback(props){

    const [loading, setLoading] = useState(true);
    const [filterByPilot, setFilterByPilot] = useState(false)
    const [allFeedback, setAllFeedback] = useState([])
    const [rows, setRows] = useState([]);
    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 200,
            renderCell: (params) => {
                const { row } = params;
                return (
                <div>
                    {row.type === "parent" && row.children ? (
                    <button onClick={() => row.toggleExpand(row.id)}>
                        {row.isExpanded ? "-" : "+"}
                    </button>
                    ) : null}
                    {row.name}
                </div>
                );
            },
        },
        {
            field: "comment",
            headerName: "Comments",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
    ]

    const toggleExpand = (id) => {
        setRows((prev) =>
          prev.map((row) =>
            row.id === id ? { ...row, isExpanded: !row.isExpanded } : row
          )
        );
    };

    const expandRow = (id) => {
        setRows((prev) =>
            prev.map((row) =>
            row.id === id ? { ...row, isExpanded: true } : row
            )
        );
    };

    const collapseRow = (id) => {
        setRows((prev) =>
            prev.map((row) =>
            row.id === id ? { ...row, isExpanded: false } : row
            )
        );
    };
      
    const flattenedRows = rows.flatMap((row) => {
        if (!row.isExpanded || !row.children) return [row];

        // Calculate aggregate value for parent rows
        const aggregateDetail = row.children.reduce(
            (sum, child) => sum + (child.detail || 0),
            0
        );

        // return [row, ...row.children];
        return [{ ...row, detail: aggregateDetail }, ...row.children];
    });

    useEffect(()=>{
        let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
        if(authPassword){
            fetch(`${SERVER_URL}/feedback`, {
                method: "get",
                headers: {
                    'Authorization': 'Basic ' + btoa(`${authPassword}`)
                }
            })
            .then(response=>{
                if (response.ok) {
                    return response.json()
                }else{
                    props.removePassword()
                    throw new Error('Unauthorized');
                }
            })
            .then(users=>{
                let rowData = []
                users.forEach((user, i)=>{
                  let newItem = {
                    id: `parent-${i}`,
                    type: "parent",
                    isExpanded: false,
                    name: user.userId.firstName + " " + user.userId.lastName,
                    pilotUser: user.userId.pilotUser ? user.userId.pilotUser : false,
                    children: []
                  }
                  user.customerFeedback.sort((a, b) => a.createdAt - b.createdAt).forEach((comment, j)=>{
                    let newComment = {
                      id: `child-${i}-${j}`,
                      type: "child",
                      comment: comment.feedback
                    }
                    newItem.children.push(newComment)
                  })
                  rowData.push(newItem)
                })
                rowData.sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                  });
                setRows(rowData)
                setAllFeedback(rowData)
                setLoading(false);
              })
              .catch(err => console.error(err));             
        }
    },[props.password])

    useEffect(()=>{
        if(filterByPilot === true){
          let filteredRows = rows.filter(row=>row.pilotUser === true)
          setRows(filteredRows)
        }else{
          setRows(allFeedback)
        }
      },[filterByPilot])


    return(
       <>
            <h1>pte-ai-prep-poc Analytics</h1>
            <Navigation/>
            <div>
                <input 
                    type="checkbox" 
                    id="pilot-user-input"
                    name="pilot-user"
                    value={filterByPilot}
                    onChange={(event)=>{setFilterByPilot(event.target.checked)}}
                    disabled={loading}
                />
                <label 
                    htmlFor='pilot-user-input'
                >
                    Show only pilot users
                </label>
            </div>
            <DataGrid
                disableColumnSorting
                disableColumnFilter
                disableRowSelectionOnClick
                rows={flattenedRows.map((row) => ({
                    ...row,
                    toggleExpand,
                }))}
                columns={columns}
                loading={loading}
                getRowId={(row) => row.id}
                slots={{
                    toolbar: () => <CustomToolbar rows={rows} expandRow={expandRow} collapseRow={collapseRow}/>,
                }}
            />
       </>
    )
}

export default CustomerFeedback