import { useEffect, useState } from 'react';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';

function CustomToolbar({rows, expandRow, collapseRow}) {
    const apiRef = useGridApiContext();

    const flattenRowsForExport = (rows) => {
        const flattened = [];
        rows.forEach((row) => {
            flattened.push(row.id);
            if (row.children) {
            row.children.forEach((child) => {
                flattened.push(child.id);
            });
            }
        });
        return flattened;
    };
  
    const handleExport = () => {
        if(expandRow){
            rows.forEach((row) => {
                if (row.children) {
                    expandRow(row.id)
                }
            });
        }
        setTimeout(()=>{
            apiRef.current.exportDataAsCsv({
                getRowsToExport: () => flattenRowsForExport(rows),
            });
            if(collapseRow){
                rows.forEach((row) => {
                    if (row.children) {
                        collapseRow(row.id)
                    }
                });
            }
        },1000)
        
    }
  
    return (
      <GridToolbarContainer>
        <button
          onClick={() => handleExport()}
        >
          Export
        </button>
      </GridToolbarContainer>
    );
}

export default CustomToolbar