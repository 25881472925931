import { useEffect, useState } from 'react';
import SERVER_URL from '../util/SERVER_URL'
import { DataGrid } from '@mui/x-data-grid';
import Navigation from './Navigation';
import CustomToolbar from './CustomToolbar';


function Users(props) {

  const [loading, setLoading] = useState(true);
  const [filterByPilot, setFilterByPilot] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div>
            {row.type === "parent" && row.children ? (
              <button onClick={() => row.toggleExpand(row.id)}>
                {row.isExpanded ? "-" : "+"}
              </button>
            ) : null}
            {row.name}
          </div>
        );
      },
    },
    {
      field: "currentScore",
      headerName: "Current score",
      width: 150,
      hide: ({ row }) => row.type !== "parent",
    },
    {
      field: "goalScore",
      headerName: "Goal score",
      width: 150,
      hide: ({ row }) => row.type !== "parent",
    },
    {
      field: "questionId",
      headerName: "Questions completed",
      width: 150,
      renderCell: (params) => {
        const { row } = params;

        if (row.type === "parent") {
            const childCount = row.children ? row.children.reduce((count) => count + 1, 0) : 0;
            return <span>{childCount} Questions completed</span>
        }else{
            return <span>Question {row.questionId}</span>
        }
      },
      valueGetter: (value, row) => {
          let newValue = "";

          if (row.type === "parent") {
            const childCount = row.children ? row.children.reduce((count) => count + 1, 0) : 0;
            newValue = `${childCount} Questions completed`
          }else{
            newValue = `Question ${row.questionId}`
          }

          return newValue;
      },
    },
    {
      field: "totalAttempts",
      headerName: "Total attempts",
      width: 150,
      renderCell: (params) => {
        const { row } = params;

        if (row.type === "parent") {
            const childSum = row.children ? row.children.reduce((sum, child) => sum + (Number(child.totalAttempts) || 0), 0) : 0;
            return <span>{childSum} Attempts made</span>
        }else{
            return <span>{row.totalAttempts} Attempts made</span>
        }
      },
      valueGetter: (value, row) => {
          let newValue = "";

          if (row.type === "parent") {
              const childSum = row.children ? row.children.reduce((sum, child) => sum + (Number(child.totalAttempts) || 0), 0) : 0;
              newValue = `${childSum} Attempts made`
          }else{
              newValue = `${row.totalAttempts} Attempts made`
          }

          return newValue;
      },
    },
    {
      field: "averageQuestionsCompleted",
      headerName: "Average number of questions completed per session",
      width: 150,
      hide: ({ row }) => row.type !== "parent",
    },
    {
      field: "averageAttempts",
      headerName: "Average number of attempts per session (all questions)",
      width: 150,
      hide: ({ row }) => row.type !== "parent",
    },
    {
      field: "noSessions",
      headerName: "No activity",
      width: 150,
      hide: ({ row }) => row.type !== "parent",
    },
  ];

  const toggleExpand = (id) => {
    setRows((prev) =>
      prev.map((row) =>
        row.id === id ? { ...row, isExpanded: !row.isExpanded } : row
      )
    );
  };

  const expandRow = (id) => {
    setRows((prev) =>
      prev.map((row) =>
        row.id === id ? { ...row, isExpanded: true } : row
      )
    );
  };

  const collapseRow = (id) => {
    setRows((prev) =>
      prev.map((row) =>
        row.id === id ? { ...row, isExpanded: false } : row
      )
    );
  };
  
  const flattenedRows = rows.flatMap((row) => {
    if (!row.isExpanded || !row.children) return [row];

    // Calculate aggregate value for parent rows
    const aggregateDetail = row.children.reduce(
      (sum, child) => sum + (child.detail || 0),
      0
    );

    // return [row, ...row.children];
    return [{ ...row, detail: aggregateDetail }, ...row.children];
  });


  useEffect(()=>{
    let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
    // console.log(authPassword)
    if(authPassword){
      fetch(`${SERVER_URL}/users`, {
          method: "get",
          headers: {
            'Authorization': 'Basic ' + btoa(`${authPassword}`)
          }
      })
      .then(response=>{
        // console.log(response)
        if (response.ok) {
          return response.json()
        }else{
          props.removePassword()
          throw new Error('Unauthorized');
        }
      })
      .then(users=>{
        let rowData = []
        users.forEach((user, i)=>{
          let newItem = {
            id: `parent-${i}`,
            type: "parent",
            isExpanded: false,
            pilotUser: user.pilotUser ? user.pilotUser : false,
            name:  user.firstName + " " + user.lastName,
            currentScore: user.goalScore.currentScore,
            goalScore: user.goalScore.goalScore,
            averageQuestionsCompleted: user.averageQuestionsCompleted,
            averageAttempts: user.averageAttempts,
            noSessions: user.noSessions ? user.noSessions : "",
            children: [],
          }
          user.questionAttempts.sort((a, b) => a.questionId - b.questionId).forEach((attempt, j)=>{
            let newAttempt = {
              id: `child-${i}-${j}`,
              type: "child",
              questionId: `${attempt.questionId}`,
              totalAttempts: `${attempt.totalAttempts}`
            }
            newItem.children.push(newAttempt)
          })
          rowData.push(newItem)
        })
        rowData.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
        setRows(rowData)
        setAllUsers(rowData)
        setLoading(false);
      })
      .catch(err => console.error(err));
    }
  },[props.password])

  useEffect(()=>{
    if(filterByPilot === true){
      let filteredRows = rows.filter(row=>row.pilotUser === true)
      setRows(filteredRows)
    }else{
      setRows(allUsers)
    }
  },[filterByPilot])

  return (
   <>
        <h1>pte-ai-prep-poc Analytics</h1>
        <Navigation/>
        <div>
          <input 
                type="checkbox" 
                id="pilot-user-input"
                name="pilot-user"
                value={filterByPilot}
                onChange={(event)=>{setFilterByPilot(event.target.checked)}}
                disabled={loading}
            />
            <label 
                htmlFor='pilot-user-input'
            >
                Show only pilot users
            </label>
        </div>
        <DataGrid
          disableColumnSorting
          disableColumnFilter
          disableRowSelectionOnClick
          rows={flattenedRows.map((row) => ({
            ...row,
            toggleExpand,
          }))}
          columns={columns}
          loading={loading}
          getRowId={(row) => row.id}
          slots={{
              toolbar: () => <CustomToolbar rows={rows} expandRow={expandRow} collapseRow={collapseRow}/>,
          }}
        />
    </>
  );
}

export default Users;
