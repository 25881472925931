import { useEffect, useState } from 'react';
import SERVER_URL from '../util/SERVER_URL'
import { DataGrid } from '@mui/x-data-grid';
import Navigation from './Navigation';
import CustomToolbar from './CustomToolbar';


function Surveys(props){

    const [loading, setLoading] = useState(true);
    const [filterByPilot, setFilterByPilot] = useState(false)
    const [allSurveys, setAllSurveys] = useState([])
    const [rows, setRows] = useState([]);
    const columns = [
        {
          field: "name",
          headerName: "Name",
          width: 200,
        },
        {
          field: "questionOne",
          headerName: "Question One",
          width: 150,
        },
        {
            field: "questionTwo",
            headerName: "Question Two",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <>
                        {
                            Array.isArray(row.questionTwo.answer) ?
                            <span>
                                {
                                    row.questionTwo.answer.map((item, i)=>{
                                        return(
                                            <span key={i}>{item} </span>
                                        )
                                    })
                                }
                            </span>
                            : null
                        }
                        {
                          typeof row.questionTwo.answer === 'string' ?
                          <span>{row.questionTwo.answer} </span>
                          : null  
                        }
                        </>
                        <>{row.questionTwo.other ? <span>(Other: {row.questionTwo.other})</span> : null}</>
                    </>
                );
              },
              valueGetter: (value, row) => {
                let newValue = "";
    
                if (Array.isArray(value.answer)) {
                    newValue += value.answer.join(", ");
                } else if (typeof value.answer === "string") {
                    newValue += value.answer;
                }
    
                if (value.other) {
                    newValue += ` (Other: ${value.other})`; 
                }
    
                return newValue;
            },
        },
        {
            field: "questionThree",
            headerName: "Question Three",
            width: 150,
        },
        {
            field: "otherFeedback",
            headerName: "Other feedback",
            width: 150,
          },
    ]

    useEffect(()=>{
        let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
        if(authPassword){
            fetch(`${SERVER_URL}/surveys`, {
                method: "get",
                headers: {
                    'Authorization': 'Basic ' + btoa(`${authPassword}`)
                }
            })
            .then(response=>{
                if (response.ok) {
                    return response.json()
                }else{
                    props.removePassword()
                    throw new Error('Unauthorized');
                }
            })
            .then(surveys=>{
                let rowData = []
                surveys.forEach((survey, i)=>{
                  let newItem = {
                    id: i,
                    name: survey.userId.firstName + " " + survey.userId.lastName,
                    pilotUser: survey.userId.pilotUser ? survey.userId.pilotUser : false,
                    questionOne: survey.surveyResponse["1"].answer,
                    questionTwo: survey.surveyResponse["2"],
                    questionThree: survey.surveyResponse["3"].answer,
                    otherFeedback: survey.surveyResponse.feedback
                  }
                  rowData.push(newItem)
                })
                rowData.sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                  });
                setRows(rowData)
                setAllSurveys(rowData)
                setLoading(false);
              })
              .catch(err => console.error(err));             
        }
    },[props.password])

    useEffect(()=>{
        if(filterByPilot === true){
          let filteredRows = rows.filter(row=>row.pilotUser === true)
          setRows(filteredRows)
        }else{
          setRows(allSurveys)
        }
      },[filterByPilot])


    return(
       <>
            <h1>pte-ai-prep-poc Analytics</h1>
            <Navigation/>
            <div>
                <input 
                    type="checkbox" 
                    id="pilot-user-input"
                    name="pilot-user"
                    value={filterByPilot}
                    onChange={(event)=>{setFilterByPilot(event.target.checked)}}
                    disabled={loading}
                />
                <label 
                    htmlFor='pilot-user-input'
                >
                    Show only pilot users
                </label>
            </div>
            <DataGrid
                disableColumnSorting
                disableColumnFilter
                disableRowSelectionOnClick
                rows={rows}
                columns={columns}
                loading={loading}
                getRowId={(row) => row.id}
                slots={{
                    toolbar: () => <CustomToolbar rows={rows}/>,
                }}
            />
       </>
    )
}

export default Surveys