import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Users from './components/Users';
import Sessions from './components/Sessions'
import Attempts from './components/Attempts';
import Surveys from './components/Surveys';
import CustomerFeedback from './components/CustomerFeedback';
import './App.css';


function App() {

  //set state to trigger rerender otherwise pages will load without knowing that sessionState had been updated
  //tried this with window.addEventListener("storage") but that is fired only when sessionStorage or localStorage changes are made from a different tab or window.
  //not a good solution but ok for now?
  const [password, setPassword] = useState(null)

  //handles when the wrong password is entered, gives another chance to retry
  const removePassword = () => {
    sessionStorage.removeItem('credentials')
    setPassword(null)
  }

  useEffect(()=>{
    const credentials = sessionStorage.getItem('credentials')
    if (!credentials) {
      let password = prompt("Password required");
      if (password) {
        sessionStorage.setItem('credentials', password);
        setPassword(password)
      } else {
        alert("You didn't enter a password");
      }
    }
  },[])

  return (
    <div className="App">
    <Router>
      <Routes>
          <Route path="/" element={<Users password={password} removePassword={removePassword}/>} />
          <Route path="/sessions" element={<Sessions password={password} removePassword={removePassword} />} />
          <Route path="/attempts" element={<Attempts password={password} removePassword={removePassword} />} />
          <Route path="/surveys" element={<Surveys password={password} removePassword={removePassword}/>} />
          <Route path="/feedback" element={<CustomerFeedback password={password} removePassword={removePassword}/>} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
