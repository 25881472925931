import { useEffect, useState } from 'react';
import SERVER_URL from '../util/SERVER_URL'
import { DataGrid } from '@mui/x-data-grid';
import Navigation from './Navigation';
import CustomToolbar from './CustomToolbar';

function Attempts(props){

    const [loading, setLoading] = useState(true);
    const [filterByPilot, setFilterByPilot] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [user, setUser] = useState(null)
    const [rows, setRows] = useState([]);
    const columns = [
        {
          field: "session",
          headerName: "Session",
          width: 150,
          renderCell: (params) => {
            const { row } = params;
        
            if (row.type === "parent") {
              return (
                <div>
                  {row.children && (
                    <button onClick={() => row.toggleExpand(row.id)}>
                      {row.isExpanded ? "-" : "+"}
                    </button>
                  )}
                  {row.session
                    ? new Date(row.session).toLocaleDateString()
                    : "No session data"}
                </div>
              );
            }
          },
        },
        // {
        //     field: "sessionDuration",
        //     headerName: "Session duration",
        //     width: 150,
        //     hide: ({ row }) => row.type !== "parent",
        // },
        {
            field: "questionId",
            headerName: "Attempts",
            width: 150,
            renderCell: (params) => {
                const { row } = params;
        
                if (row.type === "parent") {
                    const childCount = row.children ? row.children.reduce((count) => count + 1, 0) : 0;
                    const uniqueChildCount = row.children ? row.children.reduce((uniqueSet, child) => uniqueSet.add(child.questionId),new Set()).size : 0;
                    return (
                        <>
                            <span>{childCount} Attempts made</span>
                            <span> on {uniqueChildCount} Questions</span>
                        </>
                    )
                }else{
                    return <span>Question {row.questionId}</span>
                }
              },
        },
        {
            field: "attemptDate",
            headerName: "Attempt time",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "totalLoadTime",
            headerName: "Time to load score",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "overallScore",
            headerName: "Overall score",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "contentScore",
            headerName: "Content score",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "fluencyScore",
            headerName: "Fluency score",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "pronunciationScore",
            headerName: "Pronunciation score",
            width: 150,
            hide: ({ row }) => row.type !== "child",
        },
        {
            field: "audioRecording",
            headerName: "Download recording",
            width: 150,
            renderCell: (params) => {
              const { row } = params;
                // console.log(row.audioRecording)
              if (row.type === "child" && row.audioRecording) {
                  return <a href={row.audioRecording}>Download</a>
              }
            },
          },
          {
            field: "feedbackId",
            headerName: "Download full score",
            width: 150,
            renderCell: (params) => {
              const { row } = params;
      
              if (row.type === "child") {
                  return <button onClick={()=>{downloadFeedback(row.feedbackId)}}>Download</button>
              }
            },
          },
    ]

    const toggleExpand = (id) => {
        setRows((prev) =>
          prev.map((row) =>
            row.id === id ? { ...row, isExpanded: !row.isExpanded } : row
          )
        );
    };

    const expandRow = (id) => {
        setRows((prev) =>
            prev.map((row) =>
            row.id === id ? { ...row, isExpanded: true } : row
            )
        );
    };

    const collapseRow = (id) => {
        setRows((prev) =>
            prev.map((row) =>
            row.id === id ? { ...row, isExpanded: false } : row
            )
        );
    };
      
    const flattenedRows = rows.flatMap((row) => {
        if (!row.isExpanded || !row.children) return [row];

        // Calculate aggregate value for parent rows
        const aggregateDetail = row.children.reduce(
            (sum, child) => sum + (child.detail || 0),
            0
        );

        // return [row, ...row.children];
        return [{ ...row, detail: aggregateDetail }, ...row.children];
    });

    const selectUser = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const user = JSON.parse(selectedOption.getAttribute('data-user'));
        setUser(user)
    }

    const downloadFeedback = (id) => {
        let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
        if(authPassword){
            fetch(`${SERVER_URL}/download-feedback/${id}`, {
                method: "get",
                headers: {
                    'Authorization': 'Basic ' + btoa(`${authPassword}`)
                }
            })
            .then( async (response)=>{
                if(response.ok){
                    // Get the Blob from the response
                    const blob = await response.blob();

                    // Create a temporary download link
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `score_report_${id}.json`); // Specify the filename
                    document.body.appendChild(link);
                    link.click(); // Trigger the download

                    // Clean up
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url); // Release the URL object
                }
            })
        }
    }

    useEffect(()=>{
        let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
        if(authPassword){
            fetch(`${SERVER_URL}/users/simple`, {
                method: "get",
                headers: {
                    'Authorization': 'Basic ' + btoa(`${authPassword}`)
                }
            })
            .then(response=>{
                if (response.ok) {
                    return response.json()
                }else{
                    props.removePassword()
                    throw new Error('Unauthorized');
                }
            })
            .then(users=>{
                users.sort((a, b) => {
                    if (a.firstName > b.firstName) return 1;
                    if (a.firstName < b.firstName) return -1;
                    return 0;
                  });
                setAllUsers(users)
                setFilteredUsers(users)
                setUser(users[0])
            })
        }
    },[props.password])

    useEffect(()=>{
        let authPassword = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : props.password
        if(user && authPassword){
            if(loading == false){setLoading(true)}
            fetch(`${SERVER_URL}/attempts/${user._id}`, {
                method: "get",
                headers: {
                    'Authorization': 'Basic ' + btoa(`${authPassword}`)
                }
            })
            .then(response=>response.json())
            .then(groupedAttempts=>{
                // console.log(groupedAttempts)
                let rowData = []
                for (let key in groupedAttempts) {
                    if (groupedAttempts.hasOwnProperty(key)) {
                        let newItem = {
                            id: `parent-${key}`,
                            type: "parent",
                            isExpanded: false,
                            session:  groupedAttempts[key].sessionDate,
                            sessionDuration: groupedAttempts[key].sessionDuration,
                            children: [],
                        }
                        groupedAttempts[key].attempts.sort((a, b) => a.createdAt - b.createdAt).forEach((attempt, j)=>{
                            // console.log(attempt)
                            let newAttempt = {
                                id: `child-${key}-${j}`,
                                type: "child",
                                questionId: attempt.questionId,
                                feedbackId: attempt.feedbackId,
                                attemptDate: new Date(attempt.createdAt).toISOString(),
                                overallScore: attempt.processedFeedback && attempt.processedFeedback.overallPercentage ? `${attempt.processedFeedback.overallPercentage}%` : attempt.processedFeedback && attempt.processedFeedback.short_question_match !== undefined ? attempt.processedFeedback.short_question_match === true ? 'Correct' : "Incorrect" : "",
                                contentScore: attempt.processedFeedback && attempt.processedFeedback.contentOverall !== undefined ? attempt.processedFeedback.contentOverall : "",
                                fluencyScore: attempt.processedFeedback && attempt.processedFeedback.fluencyOverall ? attempt.processedFeedback.fluencyOverall : "",
                                pronunciationScore: attempt.processedFeedback && attempt.processedFeedback.pronunciationOverall ? attempt.processedFeedback.pronunciationOverall : "",
                                totalLoadTime: attempt.totalLoadTime ? attempt.totalLoadTime : "",
                                audioRecording: attempt.audioRecording ? attempt.audioRecording : ""
                            }
                            newItem.children.push(newAttempt)
                        })
                        rowData.push(newItem)
                    }
                }
                setRows(rowData)
                // console.log(rowData)
                setLoading(false);
            })
        }
    },[user, props.password])

    useEffect(()=>{
        if(filterByPilot === true){
          let newFilteredUsers = allUsers.filter(user=>user.pilotUser === true)
            setFilteredUsers(newFilteredUsers)
            setUser(newFilteredUsers[0])
        }else{
            setFilteredUsers(allUsers)
            setUser(allUsers[0])
        }
      },[filterByPilot])


    return(
        <>
            <h1>pte-ai-prep-poc Analytics</h1>
            <Navigation/>
            <div>
                <input 
                        type="checkbox" 
                        id="pilot-user-input"
                        name="pilot-user"
                        value={filterByPilot}
                        onChange={(event)=>{setFilterByPilot(event.target.checked)}}
                        disabled={loading}
                    />
                    <label 
                        htmlFor='pilot-user-input'
                    >
                        Show only pilot users
                    </label>
                </div>
            <select
                onChange={(e)=>{selectUser(e)}}
                disabled={loading}
            >
                {
                    filteredUsers.map((user, index)=>{
                        return(
                            <option 
                                key={index} 
                                data-user={JSON.stringify(user)}
                            >
                                    {user.firstName} {user.lastName}
                            </option>
                        )
                    })
                }
            </select>
            {
                user ?
                <>
                    <p>Current score: {user.goalScore.currentScore}</p>
                    <p>Goal score: {user.goalScore.goalScore}</p>
                </>
                : null
            }
            <DataGrid
                disableColumnSorting
                disableColumnFilter
                disableRowSelectionOnClick
                rows={flattenedRows.map((row) => ({
                    ...row,
                    toggleExpand,
                }))}
                columns={columns}
                loading={loading}
                getRowId={(row) => row.id}
                slots={{
                    toolbar: () => <CustomToolbar rows={rows} expandRow={expandRow} collapseRow={collapseRow}/>,
                }}
            />
        </>
    )
}

export default Attempts